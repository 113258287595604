<script setup>
import {usePage} from "@inertiajs/inertia-vue3";
import {computed} from "vue";

defineProps({
	value: String,
	margin: String
});

const page = usePage();

const textColor = computed(() => page.props.value.textColor);
const bgColor = computed(() => page.props.value.backgroundColor);
const secondaryColor = computed(() => page.props.value.secondaryColor);
const primaryColor = computed(() => page.props.value.primaryColor);


</script>

<template>
	<label :style="'color:'+textColor" :class="margin ?? 'my-auto'" class="block font-medium text-sm">
		<span v-if="value">{{ value }}</span>
		<span v-else><slot/></span>
	</label>
</template>
